import "./App.css";
import {
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

const AutoPlayAudio = () => {
  const [audioEnabled, setAudioEnabled] = useState(false);
  const audioRef = useRef(null);

  const handlePlayAudio = () => {
    audioRef.current.volume = 0.2;
    audioRef.current.play();
    setAudioEnabled(true);
  };

  return (
    <>
      {!audioEnabled && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(0, 0, 0, 0.75)"
          zIndex={9999}
        >
          <Button
            variant="contained"
            onClick={handlePlayAudio}
            sx={{
              bgcolor: "gray",
              color: "white",
              "&:hover": {
                bgcolor: "black",
              },
            }}
          >
            <Typography variant={"h5"}>Enter Tard World</Typography>
          </Button>
        </Box>
      )}
      <audio ref={audioRef} src="/music/lucky.mp3" loop />
    </>
  );
};

function App() {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [showExplosion, setShowExplosion] = useState(false);

  useEffect(() => {
    if (isLgUp) {
      const interval = setInterval(() => {
        setShowExplosion(true);
        const hideTimer = setTimeout(() => {
          setShowExplosion(false);
        }, 600);
        return () => clearTimeout(hideTimer);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isLgUp]);

  return (
    <div className="App">
      <Box
        className="background"
        width={"100vw"}
        height={"100vh"}
        position={"relative"}
        overflow={"hidden"}
      >
        <Box
          component={"img"}
          src={"/images/bg.gif"}
          alt={"rainbow"}
          width={"100%"}
          height={"100%"}
          position={"absolute"}
          top={"0"}
        />
        {isLgUp && (
          <>
            <Box
              component={"img"}
              src={"/images/towers.png"}
              alt="towers"
              position={"absolute"}
              right={"-10%"}
              bottom={"0"}
              height={"100vh"}
            />
            <Box
              position={"absolute"}
              top={"0%"}
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                className={"shake"}
                component={"img"}
                src={"/images/logo.png"}
                alt="logo"
                width={"50vw"}
                animation={"shake 0.5s"}
                animationIterationCount={"infinite"}
              />
            </Box>
            <Box
              position={"relative"}
              top={"-5%"}
              width={"100%"}
              height={"100vh"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              zIndex={10}
            >
              <Box
                className="fadeTwo"
                component={"img"}
                src={"/images/tardcat.png"}
                alt="tardcat"
                position={"absolute"}
                left={"29%"}
                zIndex={9}
                sx={{ width: { xs: "100vh", lg: "40vw" } }}
              />
              <Box
                component={"img"}
                src={"/images/tardcat.png"}
                alt="tardcat"
                position={"absolute"}
                zIndex={10}
                sx={{ width: { xs: "100vh", lg: "40vw" } }}
              />
              <Box
                className="fadeTwo"
                component={"img"}
                src={"/images/tardcat.png"}
                alt="tardcat"
                position={"absolute"}
                zIndex={8}
                left={"31%"}
                sx={{ width: { xs: "100vh", lg: "40vw" } }}
              />
            </Box>
            <Box
              className="fly"
              component={"img"}
              src={"/images/plane.png"}
              position={"absolute"}
              width={"25vw"}
              left={"0"}
              top={"20%"}
            />
            {showExplosion && (
              <Box
                component={"img"}
                src={"images/explosion.gif"}
                position={"absolute"}
                width={"25vw"}
                right={"10%"}
                top={"20%"}
                alt="explosion"
              />
            )}
            <Box
              component={"img"}
              src={"/images/mask.gif"}
              position={"absolute"}
              width={"25vw"}
              right={"10%"}
            />
            <Box
              className="fade"
              component={"img"}
              src={"/images/zyzz.png"}
              position={"absolute"}
              sx={{
                width: { lg: "40vw", xl: "35vw" },
                left: { xs: "-10%", xl: "-10%" },
                bottom: 0,
              }}
            />

            <Box
              component={"img"}
              src={"/images/trump.png"}
              position={"absolute"}
              bottom={"0"}
              width={"40vw"}
              left={"5%"}
            />
            <Box
              position={"absolute"}
              bottom={"20vw"}
              width={"20vw"}
              left={"10%"}
              zIndex={10}
              style={{ cursor: "pointer" }}
              onClick={() => alert("Broke Nigga Alert")}
            >
              <Box
                component={"img"}
                src={"/images/button.png"}
                width={"20vw"}
                left={"10%"}
                zIndex={10}
              ></Box>
              <Typography
                color={"white"}
                variant={"h3"}
                position={"absolute"}
                top={"20%"}
                left={"28%"}
                textAlign={"center"}
                fontSize={"2.5vw"}
                fontWeight={600}
              >
                FREE <br />
                MONEY
              </Typography>
            </Box>

            <Box
              component={"img"}
              src={"/images/osama.png"}
              maxWidth={"100vw"}
              position={"absolute"}
              width={"25vw"}
              bottom={"0"}
              right={"-2%"}
              alt="osama"
            />
            <Box
              className="shake"
              component={"img"}
              src={"/images/jeremy.png"}
              maxWidth={"100vw"}
              position={"absolute"}
              width={"55vw"}
              right={"2%"}
              bottom={"0"}
            />
            <Box
              position={"absolute"}
              bottom={0}
              left={0}
              right={0}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                component={"img"}
                src={"/images/boobs.gif"}
                maxWidth={"100vw"}
                width={"30vw"}
                alt="boobs gif"
              />
            </Box>
            <Box
              className="sway"
              component={"img"}
              src={"/images/crosshair.png"}
              position={"absolute"}
              bottom={"25%"}
              width={"25vw"}
              right={"-1%"}
            />
          </>
        )}

        {isSmDown && (
          <>
            <Box
              position={"absolute"}
              top={"0%"}
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              sx={{ alignItems: { xs: "center" } }}
            >
              <Box
                className={"shake"}
                component={"img"}
                src={"/images/logo.png"}
                alt="logo"
                sx={{ width: { xs: "100vw", lg: "40vw" } }}
                animation={"shake 0.5s"}
                animationIterationCount={"infinite"}
              />
            </Box>
            <Box
              position={"relative"}
              top={"-5%"}
              width={"100%"}
              height={"100vh"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              zIndex={10}
            >
              <Box
                className="fadeTwo"
                component={"img"}
                src={"/images/tardcat.png"}
                sx={{
                  width: { xs: "110vw" },
                  left: { xs: "-8%", lg: "29%" },
                }}
                alt="tardcat"
                position={"absolute"}
                zIndex={9}
              />
              <Box
                component={"img"}
                src={"/images/tardcat.png"}
                sx={{ width: { xs: "110vw" } }}
                alt="tardcat"
                position={"absolute"}
                zIndex={10}
              />
              <Box
                className="fadeTwo"
                component={"img"}
                src={"/images/tardcat.png"}
                sx={{
                  width: { xs: "110vw" },
                  left: { xs: "-2%", lg: "31%" },
                }}
                alt="tardcat"
                position={"absolute"}
                zIndex={8}
              />
            </Box>
          </>
        )}
        <Socials />
      </Box>
    </div>
  );
}

function Socials() {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      className={"gradient"}
      position={"absolute"}
      bottom={0}
      left={0}
      right={0}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      zIndex={20}
      gap={".75em"}
      margin={"0 auto"}
      padding={"0 2em"}
      sx={{ width: { lg: "30%" } }}
      flexDirection={"column"}
    >
      <Box>
        <Typography
          variant={"h5"}
          width={"100%"}
          fontSize={isSmDown ? "3vh" : "1.75vw"}
          color={"white"}
          fontWeight={600}
          style={{ overflowWrap: "anywhere" }}
          textAlign={"center"}
        >
          CA:78PFcG83Vmf5zspcfTEHAzRKvqZ7hM7VEDqQJ8i1pump
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"1.25em"}
        width={"100%"}
      >
        <Box component="a" href="https://x.com/TardCTO">
          <Box
            sx={{ width: { xs: "20vw", lg: "4vw" } }}
            borderRadius={"100%"}
            component="img"
            src="/images/socials/x.png"
          />
        </Box>

        <Box
          component="a"
          href="https://www.dextools.io/app/en/solana/pair-explorer/HmGqPnrVb7g7YK2k4PBK88zd9igqS7tRYdQw2h9jkomo?t=1721831984233"
        >
          <Box
            sx={{ width: { xs: "25vw", lg: "5vw" } }}
            component="img"
            src="/images/socials/dextools.png"
          />
        </Box>

        <Box
          component="a"
          href="https://dexscreener.com/solana/hmgqpnrvb7g7yk2k4pbk88zd9igqs7trydqw2h9jkomo"
        >
          <Box
            sx={{ width: { xs: "20vw", lg: "4vw" } }}
            borderRadius={"100%"}
            component="img"
            src="/images/socials/dex.png"
          />
        </Box>

        <Box component="a" href="https://t.me/tardinsol">
          <Box
            sx={{ width: { xs: "20vw", lg: "4vw" } }}
            borderRadius={"100%"}
            component="img"
            src="/images/socials/tg.webp"
          />
        </Box>
      </Box>
      <AutoPlayAudio />
    </Box>
  );
}

export default App;
